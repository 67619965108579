import React from "react";
import {Container, Grid, styled, Typography} from "@mui/material";
import {graphql} from "gatsby";
import {Helmet} from "gatsby-plugin-react-i18next";

import VaccinationBanner from "assets/images/punkt-szczepien-informacja-scaled.jpeg";
import SiteLayout from "components/layouts/SiteLayout";
import SectionTitle from "components/atoms/SectionTitle";

const Text = styled("p")<{ bold?: boolean }>`
  margin-bottom: 1rem;
  ${props => props.bold && "font-weight: 500"};

  ul {
    margin-block: 0;
    margin-inline: 0;
    padding-inline-start: 40px;
  }
`;

export default function CovidVaccinationPage() {
  return (
    <SiteLayout>
      <Helmet>
        <title>DokDok - Szczepienia COVID-19</title>
      </Helmet>
      <Container>
        <SectionTitle>
          Szczepienia COVID-19
        </SectionTitle>
      </Container>
      <Container>
        <Grid container alignItems="center">
          <Grid item md={6} xs={12}>
            <img
              alt="Punkt szczepień przeciwko COVID-19 grafika informacyjna"
              src={VaccinationBanner}
              width="100%"
              height="100%"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant="h2" color="primary" align="center" sx={{ mb: { xs: 4, md: 0 }}}>
              <span>DokDok</span>
              <br/>
              <span>Punkt Szczepień</span>
              <br/>
              <span>przeciwko COVID-19</span>
              <br/>
              <br/>
              <span>Al. Armii Krajowej 46ad</span>
              <br/>
              <span>50-541 Wrocław</span>
              <br/>
              <br/>
              <span>ul. Postępu 15c</span>
              <br/>
              <span>02-676 Warszawa</span>
              <br/>
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="h3" color="primary">Jak zapisać się na szczepienie?</Typography>
        <Text>
          Warunkiem zapisania się na szczepienie p. COVID-19 jest posiadanie e-skierowania.
          W większości przypadków skierowanie jest generowane automatycznie dla osób,
          które mogą zapisywać się na szczepienie.
          W niektórych przypadkach pacjenci muszą zgłosić się po ten dokument do swojego lekarza POZ.
          Dzieje się tak w sytuacji, gdy dana osoba nie ma numeru PESEL, lub w przypadku konkretnych pacjentów z
          wskazanymi chorobami – są oni o tym poinformowani.
        </Text>
        <Text>
          Jeśli chcesz dowiedzieć się, czy posiadasz aktualne skierowanie na szczepienie, możesz zadzwonić na infolinię
          Narodowego Programu Szczepień 989 albo skontaktować się z naszym Punktem Szczepień - pracownik medyczny
          zweryfikuje tę informację.
        </Text>
        <Typography variant="h3" color="primary">Od kiedy mogę się zaszczepić?</Typography>
        <Text bold>
          W punktach szczepień DokDok prowadzimy szczepienia dla osób pełnoletnich oraz dla dzieci.
        </Text>
        <Typography variant="h3" color="primary">Jak mogę umówić się na szczepienie w przychodni DokDok?</Typography>
        <Text>
          <ul>
            <li>poprzez stronę <a rel="noreferrer" href="https://pacjent.erejestracja.ezdrowie.gov.pl/"
                                  target="_blank">eRejestracje</a> lub swoje <a rel="noreferrer"
                                                                                href="https://pacjent.gov.pl"
                                                                                target="_blank">Internetowe Konto
              Pacjenta</a></li>
            <li>dzwoniąc na infolinię Narodowego Programu Szczepień <strong>989</strong></li>
            <li>wysyłając SMS o treści SzczepimySie na numer 880 333 333</li>
            <li>telefonicznie, dzwoniąc na numer przychodni DokDok <strong>506 973 850</strong></li>
          </ul>
          <br/>
          Więcej informacji znajdziesz na <a rel="noreferrer" href="https://www.gov.pl/web/szczepimysie/rejestracja"
                                             target="_blank">oficjalnej stronie programu szczepień COVID-19.</a>
        </Text>
        <Typography variant="h3" color="primary">Jak przygotować się na szczepienie?</Typography>
        <Text sx={{ pb: { xs: 0, md: 10 }}}>
          <ul>
            <li>wyśpij się przed szczepieniem</li>
            <li>ogranicz spożycie alkoholu – na kilka dni przed szczepieniem i po szczepieniu</li>
            <li>bądź aktywny fizycznie przed szczepieniem</li>
            <li>nie musisz być na czczo przed przyjęciem szczepionki</li>
            <li>nie modyfikuj i nie odstawiaj na własną rękę leków, które regularnie przyjmujesz – skonsultuj to z
              lekarzem
            </li>
            <li>sumiennie wypełnij kwestionariusz, który otrzymasz przed szczepieniem</li>
            <li>odczekaj po szczepieniu w punkcie szczepień min. 15 minut</li>
            <li>nie obawiaj się niepożądanych odczynów poszczepiennych (NOP)</li>
          </ul>
        </Text>
      </Container>
    </SiteLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

